import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Lazy-loaded components
const Layout = lazy(() => import("./layout"));
const Home = lazy(() => import("./component/Home"));
const Shop = lazy(() => import("./component/Shop"));
const ShoppingCart = lazy(() => import("./component/Shopping_Cart"));
const Promotions = lazy(() => import("./component/Promotions"));
const Cart = lazy(() => import("./component/Cart"));
const ProductDetail = lazy(() => import("./component/Product_Detail"));
const BillingInfoForm = lazy(() => import("./component/BillingInfoForm"));
const About = lazy(() => import("./component/About"));
const ViewOrder = lazy(() => import("./component/View_Order"));
const VerticalShopList = lazy(() => import("./component/VerticalShopList"));
const Login = lazy(() => import("./component/Login"));
const Registration = lazy(() => import("./component/Registration"));
const OrderHistory = lazy(() => import("./component/Order_History"));
const CartPayment = lazy(() => import("./component/Cart_Payment"));
const PayOut = lazy(() => import("./component/PayOut"));

const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="shop" element={<Shop />} />
            <Route path="cart" element={<Cart />} />
            <Route path="shopping-cart" element={<ShoppingCart />} />
            <Route path="promotions" element={<Promotions />} />
            <Route path="product/:name?" element={<ProductDetail />} />
            <Route path="billing-info" element={<BillingInfoForm />} />
            <Route path="about" element={<About />} />
            <Route path="orders/view" element={<ViewOrder />} />
            <Route path="shop/list" element={<VerticalShopList />} />
            <Route path="login" element={<Login />} />
            <Route path="registration" element={<Registration />} />
            <Route path="orders/history" element={<OrderHistory />} />
            <Route path="payment" element={<CartPayment />} />
            <Route path="checkout" element={<PayOut />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

const container = document.getElementById("root");
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
} else {
  console.error("Root element not found!");
}

reportWebVitals();
